<template>
  <div class="insert-sale-invoice">

    <div class="general-fields">
      <vs-col class="select-user-invoice md:pr-2 md:w-1/4">
        <custom-validate-input :label="$t('sales.invoices.labels.user')"
                               :styles="{width: '100%'}"
                               :is-empty="true"
                               align="right"
                               :disabled="$route.query.user > 0"
                               @input="handleGetUserSuggests($event)"
                               v-model="newInvoice.user.name"/>

        <template v-if="!$route.query.user">
          <font-awesome-icon class="suggest-modal-button"
                             icon="tasks"
                             @click="suggestUserPromptStatus = true"/>

          <font-awesome-icon class="new-suggest-modal-button"
                             icon="plus"
                             @click="newSuggestUserPromptStatus = true"/>
        </template>

        <!-- users List prompt -->
        <vs-prompt
          class="very-big-prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="suggestUserPromptStatus"
          @close="suggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('selectUsers')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.select.title')}}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="suggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content py-2 px-3">
            <template>
              <keep-alive>
                <select-users :multi-select="false" @selected="handleSuggestUserSelected($event)"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>

        <!-- insert new user prompt -->
        <vs-prompt
          class="prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="newSuggestUserPromptStatus"
          @close="newSuggestUserPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('insertUserBTN')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('users.user.insert.title') }}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="newSuggestUserPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content">
            <template>
              <keep-alive>
                <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>
      </vs-col>

      <vs-col class="select-coach-invoice md:pr-2 md:w-1/4">
        <custom-validate-input :label="$t('sales.invoices.labels.coach')"
                               :styles="{width: '100%'}"
                               :is-empty="true"
                               align="right"
                               v-model="newInvoice.coach.name"/>

        <font-awesome-icon class="suggest-modal-button"
                           icon="tasks"
                           @click="suggestCoachPromptStatus = true"/>

        <!-- coaches List prompt -->
        <vs-prompt
          class="very-big-prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="suggestCoachPromptStatus"
          @close="suggestCoachPromptStatus = true">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                <div @click="handleClick('selectUsers')">
                  <custom-icon icon="CHECK" color="success"/>
                </div>
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('coaches.select.title')}}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="suggestCoachPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content py-2 px-3">
            <template>
              <keep-alive>
                <select-active-coaches :multi-select="false" :selected-times="data" @selected="handleSuggestCoachSelected($event)"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>
      </vs-col>

      <vs-col class="md:pr-2 md:w-1/4">
        <span class="custom-select-label">{{ $t('sales.invoices.labels.actionType') }}</span>
        <custom-select class="outlined"
                       :disabled="true"
                       :default="newInvoice.actionType"
                       :options="invoiceType"
                       v-model="newInvoice.actionType"/>
      </vs-col>

      <vs-col class="md:w-1/4">
        <custom-validate-input :label="$t('sales.invoices.labels.date')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               v-model="newInvoice.date"/>
      </vs-col>
    </div>

    <vs-row class="products-fields">
      <vs-col style="height: calc(100vh - 240px)">
        <draggable-dynamic-table ref="saleInvoiceList"
                                 v-if="data.length"
                                 :in-modal="true"
                                 :active-grid="true"
                                 :fix-screen="true"
                                 :options="options"
                                 :columns="columnsLabel"
                                 @row:deleted="handleDeleteRow"
                                 @suggest:selected="handleInsertSaleAddon"
                                 @suggestPrompt:opened="handleOpenSelectSaleAddonPrompt"
                                 v-model="data"/>
      </vs-col>

      <!-- insert new product prompt -->
      <vs-prompt
        class="prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="selectSaleAddonPromptStatus"
        @close="selectSaleAddonPromptStatus = true">

        <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
          <vs-row>
            <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
              <div @click="handleClick('insertInvoiceSaleAddonsBTN')">
                <custom-icon icon="CHECK" color="success"/>
              </div>
            </vs-col>

            <vs-spacer/>

            <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
              {{ $t('sales.addons.list.title') }}
            </vs-col>

            <vs-spacer/>

            <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
              <div @click="selectSaleAddonPromptStatus = false">
                <custom-icon icon="TIMES-CIRCLE" color="danger"/>
              </div>
            </vs-col>
          </vs-row>
        </div>

        <div class="prompt-content">
          <template>
            <keep-alive>
              <select-sale-addons :user-id="newInvoice.user.id" :selected-sale-addons="data[selectedIndex].saleAddonsList || []" @inserted="handleNewSaleAddonInserted($event)"/>
            </keep-alive>
          </template>
          <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
        </div>

      </vs-prompt>
    </vs-row>

    <!-- receive from customer -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      @close="receiveFromCustomerPromptStatus = true"
      :active.sync="receiveFromCustomerPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.receive.insert.dynamicTitle', {name: newInvoice.user.name.value}) }}
          </vs-col>

          <vs-spacer/>
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <insert-fast-receive :user="newInvoice.user" :price="userDebt"
                             :subject="newInvoice.id.value ? $t('sales.invoices.receive.dynamicTitle', {id: newInvoice.id.value}) : $t('sales.invoices.receive.title')"/>

        <div class="action-buttons">
          <button class="payment" @click="handleClick('fastReceiveBTN')">{{ $t('sales.invoices.receive.labels.receiveAndReserve') }}</button>

          <button @click="routeToInvoice" class="cancel">{{ $t('sales.invoices.receive.labels.cancel') }}</button>
        </div>
      </div>
    </vs-prompt>
    <!-- /receive from customer -->

    <vs-button id="showPaymentModal" class="useral-action-button" @click="handleShowPayment"/>
    <!--    <vs-button id="insertSaleInvoiceBTN" class="useral-action-button" @click="sendData"/>-->
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment-jalaali'
  import {getUser, getUsers, getUserSaleAddons} from '@/http/requests/users/users'
  import CustomSelect from '@/components/customSelect/customSelect'
  import SelectUsers from '@/views/admin/customers/select/selectUsers'
  import CustomNumberInput from '@/components/customInput/customNumberInput'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
  import InsertUser from '@/views/admin/customers/insert/insertUser'
  import CustomIcon from '@/components/customIcon/customIcon'
  import InsertFastReceive from '../../../treasury/receive/insert/insertFastReceive'
  import {insertSaleInvoice} from '../../../../../http/requests/sales'
  import {
    addComma,
    checkUserPermissions,
    getTimeFromServer,
    hideLoading,
    showLoading
  } from "../../../../../assets/js/functions";
  import SelectSaleAddons from "./selectSaleAddons";
  import {getActiveDiscountGroup} from "../../../../../http/requests/discountGroups";
  import SelectActiveCoaches from "../../../coaches/select/selectActiveCoaches";

  export default {
    name: 'insertSaleInvoice',
    components: {
      SelectActiveCoaches,
      SelectSaleAddons,
      InsertFastReceive,
      CustomIcon,
      InsertUser,
      SuggestBox,
      CustomValidateInput,
      CustomNumberInput,
      SelectUsers,
      CustomSelect,
    },
    props: {
      selectedTimes: {},
      actionType: {}
    },
    data() {
      return {
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'delete',
            i18n: 'sales.invoices.insert.table.header.delete',
            width: '70px',
            minWidth: 70,
            /*sortable: true,*/
            actions: true,
            showAction: 'always',
            action: {
              color: 'danger',
              icon: 'icon-x',
              iconPack: 'feather',
              type: 'button'
            },
            event: 'row:deleted'
          },
          {
            field: 'finalPrice',
            i18n: 'sales.invoices.insert.table.header.finalPrice',
            width: '150px',
            minWidth: 150,
            /*sortable: true,*/
            /*editable: true,*/
            type: 'relation',
            relation: '(reservePrice)[+](saleAddons)[+](coachPrice)[-](discount)',
            relationType: 'price',
            footer: {
              type: 'auto-sum',
              textType: 'price'
            }
          },
          {
            field: 'discount',
            i18n: 'sales.invoices.insert.table.header.discount',
            width: '150px',
            minWidth: 150,
            /*sortable: true,*/
            editable: false,
            // returnValue: true,
            valueType: 'price'
          },
          {
            field: 'totalPrice',
            i18n: 'sales.invoices.insert.table.header.totalPrice',
            width: '150px',
            minWidth: 150,
            /*sortable: true,*/
            /*editable: true,*/
            type: 'relation',
            relation: '(reservePrice)[+](saleAddons)[+](coachPrice)',
            relationType: 'price',
            footer: {
              type: 'auto-sum',
              textType: 'price'
            }
          },
          {
            field: 'coachPrice',
            i18n: 'sales.invoices.insert.table.header.coachPrice',
            width: '150px',
            minWidth: 150,
          },
          {
            field: 'saleAddons',
            i18n: 'sales.invoices.insert.table.header.saleAddons',
            align: 'center',
            width: '150px',
            minWidth: 150,
            suggest: true,
            suggestPrompt: true,
            footer: {}
          },
          {
            field: 'reservePrice',
            i18n: 'sales.invoices.insert.table.header.reservePrice',
            width: '150px',
            minWidth: 150,
          },
          {
            field: 'name',
            i18n: 'sales.invoices.insert.table.header.name',
            align: 'center',
            width: 'calc(40%)',
            minWidth: 150,
            footer: {}
          },
          {
            field: 'rowNumber',
            i18n: 'sales.invoices.insert.table.header.row',
            align: 'center',
            width: '70px',
            minWidth: 70,
            autoIncrement: true,
            footer: {
              type: 'auto-counter'
            }
          }
        ],
        firstVisit: true,
        firstVisitTimer: 0,
        isTypingTimer: 0,
        usersLoadingTimer: 0,
        users: [],
        data: [],
        selectedOrders: [],
        selectedIndex: 0,
        getSaleAddonStatus: false,
        total_count: 0,
        page: 0,
        filters: [],
        suggests: {
          name: []
        },
        suggestPromptStatus: false,
        suggestCoachPromptStatus: false,
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        suggestUserAddressPromptStatus: false,
        newSuggestUserAddressPromptStatus: false,
        selectSaleAddonPromptStatus: false,
        selectInvoiceTypeStatusPrompt: false,
        receiveFromCustomerPromptStatus: false,
        maxDate: moment(getTimeFromServer()).format(this.$validator('moment.date')),
        newInvoice: {
          id: {
            value: '',
            isValid: true
          },
          actionType: 0,
          status: {},
          saleAddons: [],
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            },
            characterId: 0,
          },
          coach: {
            id: null,
            name: {
              value: '',
              isValid: true
            },
            price: 0
          },
          address: {
            details: {
              value: '',
              isValid: true
            },
            address: {}
          },
          date: {
            value: moment(getTimeFromServer()).format(this.$validator('moment.date')),
            isValid: true
          },
          finalPrice: 0,
          totalPrice: 0,
        },
        activeDiscounts: [],
        userDebt: 0,
        invoiceProcesses: [
          {
            label: this.$t('sales.invoices.statusTypes.confirmed'),
            value: 2
          }
        ],
        invoiceType: [
          {
            label: this.$t('sales.invoices.actionTypes.present'),
            value: 1
          },
          {
            label: this.$t('sales.invoices.actionTypes.tel'),
            value: 2
          }
        ],
      }
    },
    created() {
      if (!checkUserPermissions('invoice.set_discount')) {
        const discountIndex = this.columnsLabel.map(e => e.field).indexOf('discount')
        if (discountIndex > -1) {
          this.columnsLabel[discountIndex].editable = false
        }
      }

      this.getUserDefault()
      this.selectedOrders = Object.values(this.selectedTimes)
      // this.getUsers()
      this.getReserves()
    },
    methods: {
      getUserDefault () {
        this.newInvoice.status = this.invoiceProcesses[0]
        this.newInvoice.actionType = this.actionType

        if (this.$route.query.user) {
          this.newInvoice.user.id = this.$route.query.user
          getUser(this.newInvoice.user.id).then(response => {
            this.newInvoice.user.name.value = `${response.data.data.name} ${response.data.data.family}`
            showLoading()
            setTimeout(() => {
              this.getUserDiscounts()
            }, 100)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
        }
      },
      getUsers(name = '') {
        clearTimeout(this.usersLoadingTimer)
        this.usersLoadingTimer = setTimeout(() => {
          this.users = []
          const filters = name.length > 0 ? [`full_name=${name}`] : []
          getUsers(1, filters).then(response => {

            response.data.data.forEach((user) => {
              this.users.push({
                label: `${user.name} ${user.family}`,
                value: user.id
              })
              // this.newInvoice.user = 0
            })
          })
            .catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              }
            })
        }, 500)
      },
      getUserDiscounts() {
        getActiveDiscountGroup(this.newInvoice.user.id).then(response => {
          // this.activeDiscounts = response.data.data
          const discounts = response.data.data
          this.activeDiscounts = []
          discounts.forEach(discount => {
            this.activeDiscounts.push({
              termId: discount.term.id,
              price: discount.price,
              percent: discount.percent
            })
          })
          if (checkUserPermissions('invoice.set_discount')) {
            const discountIndex = this.columnsLabel.map(e => e.field).indexOf('discount')
            if (discountIndex > -1) {
              this.columnsLabel[discountIndex].editable = true
            }
          }

          this.getUserSaleAddons()
        })
          .catch(error => {
            hideLoading()
            switch (error.response.status) {
              case 400:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.invoices.notifications.userGroup', {name: this.newInvoice.user.name.value}),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.invoices.notifications.discountsError'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          })
      },
      getUserSaleAddons () {
        this.getSaleAddonStatus = false
        getUserSaleAddons(this.newInvoice.user.id).then(response => {
          this.newInvoice.saleAddons = []
          const saleAddons = response.data.data
          if (saleAddons && saleAddons.length > 0) {
            saleAddons.forEach(saleAddon => {
              if (saleAddon.required) {
                this.newInvoice.saleAddons.push({
                  rowNumber: this.newInvoice.saleAddons.length + 1,
                  id: saleAddon.id,
                  name: saleAddon.name,
                  quantity: 1,
                  maxSelect: saleAddon.max_select,
                  unitPrice: {
                    value: saleAddon.price,
                    type: 'price'
                  },
                  finalPrice: {
                    type: 'price',
                    value: saleAddon.price.toString()
                  },
                  required: saleAddon.required
                })
              }
            })

            setTimeout(() => {
              for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].saleAddonsList.length === 0) {
                  this.selectedIndex = i
                  this.handleNewSaleAddonInserted(this.newInvoice.saleAddons)
                }
              }
            }, 50)
            this.getSaleAddonStatus = true
          } else {
            for (let i = 0; i < this.data.length; i++) {
              setTimeout(() => {
                this.selectedIndex = i
                this.handleNewSaleAddonInserted([])
              }, 10)
            }
          }
          hideLoading()
          this.data = []
          this.getReserves()
        })
          .catch(error => {
            hideLoading()
            this.getSaleAddonStatus = true
          })
      },
      getReserves() {
        // get reserves for suggests
        const reserves = this.selectedOrders
        let totalPrice = 0
        reserves.forEach((reserve) => {
          const data = {
            id: this.data.length + 1,
            index: this.selectedOrders.indexOf(reserve),
            status: 'reserve',
            name: `${reserve.court.court_name} - ${reserve.time.start_time.substr(0, 5)} تا ${reserve.time.end_time.substr(0, 5)} - ${reserve.date}`,
            courtName: reserve.court.court_name,
            court: reserve.court,
            time: reserve.time,
            term: reserve.term,
            date: reserve.date,
            termId: reserve.termId,
            timeId: reserve.time.id,
            timeReserve: `${reserve.time.start_time.substr(0, 5)} تا ${reserve.time.end_time.substr(0, 5)} `,
            reservePrice: {
              value: reserve.price,
              type: 'price'
            },
            coachPrice: {
              value: this.newInvoice.coach.price,
              type: 'price'
            },
            totalPrice: {
              value: reserve.price,
              type: 'price'
            },
            finalPrice: {
              value: reserve.price,
              type: 'price'
            },
            saleAddonsList: [],
            discount: addComma(reserve.discount),
          }
          this.data.push(data)
        })
      },
      handleShowPayment() {
        if (this.newInvoice.user.id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.validators.user'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        } else if (this.data.length === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.validators.noTime'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        } else {
          this.sendData()
        }
      },
      handleGetUserSuggests(search) {
        // this.users = []
        // this.$refs.usersSuggestDropDown.show = true
        // this.getUsers(search.value)
      },
      handleUserSuggestSelected(user) {
        this.newInvoice.user.id = user.value
        this.newInvoice.user.name.value = user.label
        showLoading()
        setTimeout(() => {
          this.getUserDiscounts()
        }, 100)
      },
      handleNewUserInserted(user) {
        this.newInvoice.user.id = user.id
        this.newInvoice.user.name.value = `${user.name} ${user.family}`
        this.newSuggestUserPromptStatus = false
        showLoading()
        setTimeout(() => {
          this.getUserDiscounts()
        }, 100)
      },
      handleNewSaleAddonInserted(list) {
        this.data[this.selectedIndex].saleAddonsList = []
        let totalPrice = 0
        if (list.length > 0) {
          list.forEach(item => {
            let row = JSON.parse(JSON.stringify(this.data[this.selectedIndex]))
            row.saleAddonsList.push(item)
            this.data[this.selectedIndex] = row
            totalPrice += parseInt(item.finalPrice.value.split(',').join(''))
          })
        }
        let selectedRow = JSON.parse(JSON.stringify(this.data))
        selectedRow[this.selectedIndex].saleAddons = {
          value: totalPrice,
          type: 'price'
        }

        const index = this.activeDiscounts.map(e => e.termId).indexOf(selectedRow[this.selectedIndex].termId)
        if (index > -1) {
          let discount = 0
          if (this.activeDiscounts[index].percent > 0) {
            discount = (parseInt(selectedRow[this.selectedIndex].reservePrice.value.split(',').join('') || 0) + parseInt(this.newInvoice.coach.price) + totalPrice) * (this.activeDiscounts[index].percent / 100)
          } else {
            discount = this.activeDiscounts[index].price
          }
          selectedRow[this.selectedIndex].discount = addComma(discount)
        }
        this.data = selectedRow
        this.selectSaleAddonPromptStatus = false
      },
      handleSuggestUserSelected(list) {
        let user = {}
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            user = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2
            }
          }
        })
        this.newInvoice.user = user
        this.suggestUserPromptStatus = false
        showLoading()
        setTimeout(() => {
          this.getUserDiscounts()
        }, 100)
      },
      handleSuggestCoachSelected(list) {
        let coach = {}
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            coach = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2,
              price: parseInt(data.price.value)
            }

            this.data.forEach(reserve => {
              reserve.coachPrice.value = data.price.value
            })
          }
        })
        this.data.forEach(reserve => {
          const index = this.activeDiscounts.map(e => e.termId).indexOf(reserve.termId)
          if (index > -1) {
            let discount = 0
            if (this.activeDiscounts[index].percent > 0) {
              discount = (parseInt(reserve.totalPrice.value.split(',').join('') || 0) - parseInt(this.newInvoice.coach.price) + coach.price) * (this.activeDiscounts[index].percent / 100)
            } else {
              discount = this.activeDiscounts[index].price
            }
            reserve.discount = addComma(discount)
          }
        })
        this.newInvoice.coach = coach
        this.suggestCoachPromptStatus = false
      },
      handleDeleteRow(row) {
        if (row) {
          const index = this.data.map((elm) => {
            return elm.id
          }).indexOf(row.id)
          if (index > -1 && row.id !== 0 && this.data.length > 1) {
              this.selectedIndex = 0
              this.data.splice(index, 1)
              this.selectedOrders.splice(index, 1)
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('sales.invoices.validators.emptyTime'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.notifications.cantDeleteAddon'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      sendData() {
        let errorNum = 0
        const reserves = []
        this.data.forEach((reserve) => {
          let addons = []
          if (reserve.saleAddonsList.length > 0) {
            reserve.saleAddonsList.forEach((item) => {
              // if (!item.required) {
                addons.push({
                  sale_addon_id: item.id,
                  quantity: item.quantity
                })
              // }
            })
          }
          if (reserve.id && reserve.id !== 0) {
            const sumPrices = parseInt(reserve.reservePrice.value) + reserve.saleAddons.value + parseInt(reserve.coachPrice.value)
            if (parseInt(reserve.discount.split(',').join('') || '0') <= sumPrices) {
              reserves.push({
                time_id: reserve.time.id,
                court_id: reserve.court.id,
                term_id: reserve.termId,
                gender: this.newInvoice.user.gender,
                date: reserve.date,
                unit_price: reserve.finalPrice.toString().replaceAll(',', ''),
                discount: reserve.discount ? parseInt(reserve.discount.toString().replaceAll(',', '')) : 0,
                quantity: reserve.count,
                addons: addons
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('sales.invoices.notifications.discountIsBigger', {name: reserve.name}),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400
              })
              errorNum++
            }
          }
        })

        if (errorNum === 0) {
          const data = {
            status: this.newInvoice.status.value,
            action_type: this.newInvoice.actionType.value,
            invoice_number: this.newInvoice.id.value ? parseInt(this.newInvoice.id.value) : null,
            lines: reserves,
            user_id: this.newInvoice.user.id,
            coach_id: this.newInvoice.coach.id
          }

          if (this.newInvoice.address.address) data.address = this.newInvoice.address.address

          insertSaleInvoice(data).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('sales.invoices.notifications.insert.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400
            })
            this.$router.replace({name: 'saleInvoices'})
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'date': this.$t('sales.invoices.notifications.parseError.date'),
              }
              switch (error.response.status) {
                case 409:
                  // if (error.response.data && error.response.data.data && error.response.data.data.hasOwnProperty('user_debt')) {
                  const newInvoice = error.response.data

                  if (newInvoice.error_type === 'balance_error') {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.lowMoney'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })

                    this.userDebt = Math.abs(newInvoice.money_deficit)
                    // this.newInvoice.id.value = newInvoice.id
                    this.receiveFromCustomerPromptStatus = true
                  } else if (newInvoice.error_type === 'coach_error') {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.coachCantSelect'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })
                    let reserveList = JSON.parse(JSON.stringify(this.data))
                    reserveList.forEach((item, itemIndex) => {
                      if (newInvoice.conflicts.indexOf(itemIndex) > -1) {
                        item.styles = 'background: rgba(255, 0, 0, 0.23);'
                      } else {
                        item.styles = ''
                      }
                    })
                    this.data = reserveList

                  } else {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.timesReserved'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                  }
                  break

                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.insert.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      },
      routeToInvoice() {
        // window.open(this.$router.resolve({name: 'print-sale-invoice-page', params:{id: this.newInvoice.id.value}}).href, '_blank')
        this.$router.replace({name: 'saleInvoices'})
      },
      handleReloadPrompt() {
        this.suggestPromptStatus = false
        setTimeout(() => {
          this.suggestPromptStatus = true
        }, 100)
      },
      handleInsertSaleAddon (row) {
      },
      handleOpenSelectSaleAddonPrompt (index) {
        if (this.newInvoice.user.id > 0) {
          if (this.getSaleAddonStatus) {
            this.selectSaleAddonPromptStatus = true
            this.selectedIndex = index
          } else {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              color: 'warning',
              time: 5000,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.validators.user'),
            color: 'danger',
            time: 5000,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      }
    },
    watch: {
      'newInvoice': {
        handler() {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 500)

          /*if (!this.firstVisit) {
            this.$store.dispatch('setPageChanges')
          }*/
        },
        deep: true
      },
      '$store.state.helper.userAddressesChanged': {
        handler(val) {
          this.newInvoice.address.details = {
            isValid: true,
            value: `${val.province}, ${val.city}, ${val.address}`
          }
          this.newInvoice.address.address = {
            province: val.province,
            city: val.city,
            address: val.address,
            post_code: val.post_code,
            receiver_name: val.receiver_name,
            receiver_family: val.receiver_family,
            receiver_call_number: val.receiver_call_number
          }
          this.newSuggestUserAddressPromptStatus = false
        },
        deep: true
      },
      '$store.state.helper.documentsChanged': {
        handler(val) {
          if (val) {
            this.sendData()
          }
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .insert-sale-invoice {

    .general-fields, .products-fields {
      padding: .5rem;
    }

    .general-fields {
      display: flex;
      flex-wrap: wrap;
      width: unset;
      margin: 0.5rem;
      background: #cecece22;
      border: 1px solid #cecece;
      border-radius: 0.5rem;
      padding-bottom: 0;

      div.vs-col {
        position: relative;
      }

      .custom-validate-input label {
        background-color: #ffffff;
      }

      .custom-validate-input label span.is-focus,
      .custom-number-input label span.is-focus,
      span.custom-select-label {
        backdrop-filter: sepia(1);
        background: linear-gradient(180deg, #f8f8f8, #ffffff);
      }

      span.custom-select-label {
        font-size: 12px;
        position: absolute;
        padding: 0 5px;
        left: 5px;
        top: 5px;
        z-index: 201;
      }
    }

    .select-user-invoice, .select-user-address-invoice, .select-coach-invoice {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 42px;
        top: 20px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 12px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      .suggest-box {

        ul {
          margin-top: -10px;
          border-radius: .5rem;
          padding: 0 10px;
          line-height: 35px;
          background: white;
          box-shadow: 0 5px 10px -8px;
          border: 1px solid #cecece;
          right: .5rem;
          /*left: .5rem;*/

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            cursor: pointer;
            border-top: 1px solid #cecece;

            &:first-child {
              border-top: none !important;
            }
          }
        }
      }

      &.select-coach-invoice {
        .suggest-modal-button {
          right: 12px;
        }
      }
    }

    table {
      border: 1px solid #1b1b1c;
      border-radius: 5px;

      tr {

        div.select-suggest-list {
          right: 0 !important;
          left: 0 !important;
        }
      }
    }


    .draggable-dynamic-table {
      .new-suggest-modal-button {
        display: none;
      }

      .suggest-modal-button {
        right: 7px !important;
      }
    }
  }

  .action-buttons {
    display: flex;

    button {
      flex: 1;
      line-height: 40px;
      border-radius: .5rem;
      border: none;
      color: #ffffff;
      transition: all .3s ease;
      cursor: pointer;

      &.payment {
        margin-right: 10px;
        background: #33b000cf;

        &:hover {
          background: #33b000;
        }
      }

      &.cancel {
        background: #b00000af;

        &:hover {
          background: #b00000;
        }
      }
    }
  }
</style>
